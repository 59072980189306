'use client';

import { useEffect } from 'react';
import propTypes from 'prop-types';

const GlobalErrorPage = ({ error }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);
      
  return (
    <>
      <h1>500</h1>
      <h3>האתר בתחזוקה</h3>
      <h4>נחזור לפעילות  מאוחר יותר</h4>
    </>
  );
};

GlobalErrorPage.propTypes = {
  error: propTypes.object,
  reset: propTypes.func
};
  
export default GlobalErrorPage;
  